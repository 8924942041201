<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-03-24 11:05:03
 * @LastEditors: pj
 * @LastEditTime: 2023-04-26 15:18:02
-->
<template>
  <div>
    <gl-title title="商品详情" backIcon></gl-title>
    <div class="details">
      <div class="details-top">
        <el-carousel class="details-top-img" height="455px">
          <el-carousel-item
            v-for="item in goodsInfo.goodsItemPictureVOS"
            :key="item.pictureId"
          >
            <img :src="item.pictureUrl" alt="" />
          </el-carousel-item>
        </el-carousel>

        <div class="details-top-untion">
          <div class="details-top-untion-title">{{ goodsInfo.itemName }}</div>
          <div class="details-top-untion-secondTitle">
            <img src="@/assets/tuijian.png" alt="" />
            <span>{{ goodsInfo.productName }}</span>
          </div>
          <div class="details-top-untion-price">
            <span class="details-top-untion-price-title1">价格：</span>
            <span class="details-top-untion-price-title2">¥</span>
            <span class="details-top-untion-price-title3">{{
              choiceSizeIndex !== null
                ? goodsInfo.goodsSkuDTOS[choiceIndex].goodsSkuSizeDTOList[
                    choiceSizeIndex
                  ].batchPrice
                : goodsInfo.batchMinimumPrice
            }}</span>
            <span class="details-top-untion-price-title4">/个</span>
            <span class="details-top-untion-price-title5"
              >建议零售价： ¥{{
                choiceSizeIndex !== null
                  ? goodsInfo.goodsSkuDTOS[choiceIndex].goodsSkuSizeDTOList[
                      choiceSizeIndex
                    ].retailPrice
                  : goodsInfo.highestPrice
              }}/个</span
            >
          </div>

          <div class="details-top-untion-version">
            <div class="details-top-untion-version-titile">款式：</div>
            <div class="details-top-untion-version-choice">
              <div v-for="(item, index) in goodsInfo.goodsSkuDTOS" :key="index">
                <div
                  :class="
                    choiceIndex === index
                      ? 'details-top-untion-version-choice-optionsConfirm'
                      : 'details-top-untion-version-choice-options'
                  "
                  @click="choiceName(index)"
                >
                  {{ item.skuName }}
                </div>
              </div>
            </div>
          </div>

          <div class="details-top-untion-version">
            <div class="details-top-untion-version-titile">规格：</div>
            <div
              class="details-top-untion-version-choice"
              v-if="choiceIndex !== null"
            >
              <div
                v-for="(item, index) in goodsInfo.goodsSkuDTOS[choiceIndex]
                  .goodsSkuSizeDTOList"
                :key="index"
              >
                <div
                  :class="
                    choiceSizeIndex === index
                      ? 'details-top-untion-version-choice-optionsConfirm'
                      : 'details-top-untion-version-choice-options'
                  "
                  @click="choiceSize(index)"
                >
                  {{ item.skuSize }}
                </div>
              </div>
            </div>
          </div>

          <div class="details-top-untion-num">
            <div class="details-top-untion-num-title">数量：</div>

            <el-input-number
              v-if="this.type == 0"
              v-model="num"
              :step="goodsInfo.batchQuantity"
              size="small"
              step-strictly
              @change="handleChange"
              :min="goodsInfo.batchQuantity"
              :max="maxNum"
            ></el-input-number>
            <el-input-number
              v-else
              v-model="num"
              size="small"
              step-strictly
              @change="handleChange"
              :min="1"
              :max="maxNum"
            ></el-input-number>
            <div class="details-top-untion-num-unit">个</div>
          </div>

          <div
            class="details-top-untion-confirm"
            @click="goRouter"
            v-if="type == 0"
          >
            立即下订
          </div>
          <div class="details-top-untion-confirm" @click="goRouter" v-else>
            立即拼团
          </div>
        </div>
      </div>

      <el-divider></el-divider>
      <div class="details-middle">
        <div
          v-for="(item, index) in attributes"
          :key="index"
          class="details-middle-content"
        >
          <div>{{ item.name }}</div>
          <div :title="item.value">{{ item.value }}</div>
        </div>
      </div>
      <div class="details-bottom">
        <div v-html="newInfo" style="width: 750px"></div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { itemDetailInfo } from '@/api/coox/shop.js'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  data() {
    // 这里存放数据
    return {
      goodsInfo: {},
      choiceIndex: null,
      choiceSizeIndex: null,
      num: 1,
      type: null, // 判断是否为团购，1为团购，0为普通
    }
  },
  // 计算属性类似于data概念
  computed: {
    maxNum() {
      if (this.choiceIndex == null || this.choiceSizeIndex == null) {
        return 1
      }
      const num =
        this.goodsInfo.goodsSkuDTOS[this.choiceIndex].goodsSkuSizeDTOList[
          this.choiceSizeIndex
        ].inventory
      return num
    },
    newInfo() {
      let newContent = this.goodsInfo.itemDetail
        ?.replace(/\<p/gi, '<p style="display: flex;flex-direction: column;"')
        ?.replace(
          /\<img/gi,
          '<img style="width:100%;height:auto;margin:0rpx;padding:0rpx;vertical-align:bottom;"'
        )
      return newContent
    },
    attributes() {
      let result = [
        { name: 'IP:', value: this.goodsInfo.itemName },
        { name: '系列:', value: this.goodsInfo.productName },
        { name: '材质:', value: this.goodsInfo.productMaterial },
        { name: '设计师:', value: this.goodsInfo.designerName },
        { name: '尺寸:', value: this.goodsInfo.productSize },
        { name: '编码:', value: this.goodsInfo.productNumber },
      ]
      return result
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    choiceName(target) {
      this.choiceIndex = target
    },
    choiceSize(target) {
      this.choiceSizeIndex = target
    },
    handleChange(value) {
      console.log(value)
    },
    goRouter() {
      this.$router.push({
        path: 'createOrder',
        query: {
          type: this.type,
          itemId: this.goodsInfo.goodsSkuDTOS[this.choiceIndex].itemId,
          num: this.num,
          skuId:
            this.goodsInfo.goodsSkuDTOS[this.choiceIndex].goodsSkuSizeDTOList[
              this.choiceSizeIndex
            ].skuId,
        },
      })

      // this.$router.push({ path: 'createOrder' })
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  async mounted() {
    const resutl = await itemDetailInfo(this.$route.query.itemId)
    this.type = this.$route.query.type
    this.goodsInfo = resutl.data
    if (this.$route.query.type == 0) {
      this.num = resutl.data.batchQuantity
    } else {
      this.num = 1
    }
    this.choiceIndex = 0
    this.choiceSizeIndex = 0
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.details {
  background-color: #ffffff;
  margin: 30px 0 0 30px;
  width: 1100px;
  height: auto;
  box-sizing: border-box;
  padding: 25px;
  &-top {
    display: flex;
    &-img {
      width: 455px;
      border-radius: 10px;
      img {
        width: 455px;
        height: 455px;
        border-radius: 10px;
      }
    }
    &-untion {
      margin-left: 25px;
      &-title {
        font-family: PingFang SC;
        font-size: 24px;
        font-weight: bold;
        color: #3d3d3d;
        margin-bottom: 3px;
      }
      &-secondTitle {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: normal;
        color: #878787;
        display: flex;
        align-items: center;
        img {
          width: 48px;
          height: 17px;
          margin-right: 10px;
        }
      }
      &-price {
        vertical-align: bottom;
        &-title1 {
          font-family: PingFang SC;
          font-size: 16px;
          letter-spacing: 0.05em;
          color: #878787;
        }
        &-title2 {
          font-family: DIN Black;
          font-size: 20px;
          color: #e94709;
        }
        &-title3 {
          font-family: DIN;
          font-size: 40px;
          font-weight: bold;
          color: #e94709;
        }
        &-title4 {
          font-family: PingFang SC;
          font-size: 20px;
          color: #3d3d3d;
        }
        &-title5 {
          margin-left: 9px;
          font-size: 14px;
          color: #878787;
        }
      }
      &-version {
        display: flex;
        margin-top: 30px;
        &-titile {
          width: 60px;
          font-family: PingFang SC;
          font-size: 16px;
          color: #878787;
          margin-right: 30px;
        }
        &-choice {
          display: flex;
          flex-wrap: wrap;
          &-options {
            width: fit-content;
            height: 40px;
            border-radius: 6px;
            opacity: 1;
            background: #f2f5f7;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin: 0 10px 10px 0;
            box-sizing: border-box;
            padding: 10px 20px;
            font-weight: bold;
          }
          &-optionsConfirm {
            width: fit-content;
            height: 40px;
            border-radius: 6px;
            opacity: 1;
            background: #e1f0ff;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-weight: bold;
            margin: 0 10px 10px 0;
            box-sizing: border-box;
            padding: 10px 20px;
          }
        }
      }
      &-num {
        display: flex;
        align-items: center;
        margin-top: 30px;
        &-title {
          width: 60px;
          font-family: PingFang SC;
          font-size: 16px;
          color: #878787;
          margin-right: 30px;
        }
        &-unit {
          font-family: PingFang SC;
          font-size: 16px;
          margin-left: 10px;
          color: #878787;
        }
      }
      &-confirm {
        width: 250px;
        height: 50px;
        border-radius: 10px;
        opacity: 1;
        background: #409eff;
        box-shadow: 0px 4px 10px 0px rgba(64, 158, 255, 0.3);
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: PingFang SC;
        font-size: 20px;
        color: #ffffff;
      }
    }
  }
  &-middle {
    width: 100%;
    height: 150px;
    box-sizing: border-box;
    padding: 40px 0px 40px 0px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    &-content {
      display: flex;
      width: 300px;
      height: 18px;
      font-size: 20px;
      line-height: 18px;
      color: #878787;
      margin-bottom: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &-bottom {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    padding-left: 130px;
  }
}
</style>
