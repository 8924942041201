/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-03-23 15:48:06
 * @LastEditors: pj
 * @LastEditTime: 2023-03-29 15:39:05
 */
import request from '@/plugins/request'
//订单管理
// export function orderList(data = {}){
//   return request({
//     url: `/api/order/parent/getOrderList`,
//     method: 'POST',
//     data: data,
//   })
// }

//请求商品列表
export function queryByType (data = {}) {
  const parmas = {
    input: {
      queryType: 1,
      isBatch: '1',
      itemName: data.itemName,
      firstCategory: data.firstCategory,
      secondCategory: data.secondCategory
    },
    pageSize: data.pageSize,
    pageNum: data.pageNum
  }
  return request({
    url: `/api/goods/item/queryByType`,
    method: 'POST',
    data: parmas,
  })
}

//确认订单
export function orderAffirm (data = {}) {
  return request({
    url: `/api/order/detailed/orderAffirm`,
    method: 'POST',
    data: data,
  })
}

export function newAddress (data = {}) {
  return request({
    url: `/api/user/address/newAddress`,
    method: 'POST',
    data: data,
  })
}



export function updateAddress (data = {}) {
  return request({
    url: `/api/user/address/updateAddress`,
    method: 'POST',
    data: data,
  })
}


//新建订单
export function newOrder (data = {}) {
  return request({
    url: `/api/order/detailed/newOrder`,
    method: 'POST',
    data: data,
  })
}

//获取订单支付信息
export function orderPayInfo (data = {}) {
  return request({
    url: `/api/order/detailed/orderPayInfo`,
    method: 'POST',
    data: data,
  })
}

//下单支付
export function orderPayment (data = {}) {
  return request({
    url: `/api/order/detailed/orderPayment`,
    method: 'POST',
    data: data,
  })
}


//获取支付结果
export function orderPayInfoResult (data = {}) {
  return request({
    url: `/api/money/paymentOrder/orderPayInfoResult`,
    method: 'POST',
    data: data,
  })
}



// 订单详情
// export function getCategoryTree(orderId = ''){
//   return request({
//     url: `/api/goods/category/getCategoryTree`,
//     method: 'GET',
//     params: {orderId},
//   })
// }

// 获取商品类目下拉（树形
export function getCategoryTree () {
  return request({
    url: `/api/goods/category/getCategoryTree`,
    method: 'GET',
  })
}

//请求默认地址
export function getListByUserId () {
  return request({
    url: `/api/user/address/getListByUserId`,
    method: 'GET',
  })
}


export function itemDetailInfo (itemId = '') {
  return request({
    url: `/api/goods/item/itemDetailInfo`,
    method: 'GET',
    params: { itemId },
  })
}


// 手动发货
// export function numberSend(data = {}){
//   return request({
//     url: `/api/order/logistic/numberSend`,
//     method: 'POST',
//     data: data,
//   })
// }



export default {
  getCategoryTree,
  queryByType,
  itemDetailInfo,
  orderAffirm,
  getListByUserId,
  newAddress,
  updateAddress,
  orderPayInfoResult,
  orderPayInfo,
  newOrder,
  orderPayment
}